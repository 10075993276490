body {
    overflow-x: hidden;
}

/* CHECK DO CARRINHO */
#checked {
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
    width: 2.5rem;
    height: 2.5rem;
    z-index: 90000;
    cursor: pointer;
    opacity: 0;
    display: none;
}

.btn-shop {
    padding: 15px;
    position: static;
    right: 20px;
    font-size: 15px;
    cursor: pointer;
    display: inline-block;
    color: var(--headerIcons-bg-color);
    border-radius: 2rem;
    transition: .3s;
    text-transform: uppercase;
}

.btn-shop:hover {
    color: #423e3e;
    /* alterar */
}

.btn-shop-responsivo {
    color: var(--headerIcons-bg-color);
    padding: 15px;
    position: absolute;
    z-index: 1;
    right: 3rem;
    font-size: 20px;
    cursor: pointer;
    display: inline-block;
    padding: .5rem 1.3rem;
    font-size: 13px;
    margin: 0 .2rem;
    display: none;
}

.container-cart {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    margin-top: 42px;
    text-align: center;
}

.cart {
    width: 445px;
    position: absolute;
    height: 100%;
    max-height: 100%;
    right: -480px;
    border: 1px solid;
    transition: all 1.5s ease 0s;
    background-color: var(--main-bg-color);
    color: black;
    display: flex;
    z-index: 1;
    padding: 0px 5px 0px 5px;
    flex-direction: column;
}

.actived {
    transform: translateX(-480px);
}

/*#region HEADER*/

.cart-header {
    padding: 5px 0px 0px 0px;

}

.bloco-pin {
    display: flex;
    justify-content: flex-end;
    padding: 0 5px;
}

.inputPin {
    display: none;
}

.inputPin~.labelPin {
    transform: rotate(90deg);
}

.inputPin:checked~.labelPin {
    transform: translateY(0px) rotate(0deg);
}

.labelPin {
    color: black;
    display: flex;
    cursor: pointer;

}

/*#endregion */

/*#region BODY*/

.cart-info {
    font-size: 14px;
    width: 100%;
    margin-bottom: 10px;
}

.cart-title {
    border: 3px solid rgba(32, 44, 53, 0.37);
    display: flex;
    font-size: 15px;
    justify-content: space-around;
    font-weight: bold;
    margin: 10px 0px;

}

.cards-cart {
    padding: 0px 10px;
    overflow-y: scroll;
    height: 400px;
}

.card-cart {
    width: 100%;
    display: flex;
    height: 90px;
    min-height: 90px;
    max-height: 90px;
    margin-bottom: 10px;
}

.card-cart-image {
    display: inline-block;
    width: 20%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
}

.container-image {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    width: 100%;
}

.cart-img {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: center;
    color: #111;
}

.card-cart-infos {
    width: 100%;
    height: 100%;
    margin-left: 5px;
}

.card-cart-title {
    width: 100%;
    height: 45%;
    text-align: left;
    font-size: 13px;
    font-weight: bold;
}

.card-cart-title:hover {
    color: #423e3e;
}

.btnRemoverProduto {
    display: flex;
    align-items: center;
}

.card-cart-buttons {
    height: 50%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

.btnRemoverProduto svg {
    color: black;
    width: 15px !important;
    height: 15px;
    margin-right: -10%;
    margin-left: 5%;
    cursor: pointer;
}

/*#endregion */

/*#region FOOTER*/

.descontoeTotalFinal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 5px;
}

.descontoeTotalFinal {
    font-size: 13px;
    font-weight: bold;
}

.produto-titulo {
    width: 100%;
    text-align: left;
}

.totais {
    text-align: end;
}

.botoes {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.finalizar {
    display: inline-block;
}

.btn {
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    padding: 10px;
    border-color: var(--botoesFiltrarFinalizar-bg-color);
    border-width: 1px;
    border-style: solid;
    box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 20%);
    max-width: 100%;
}

.btn:active {
    position: relative;
    top: 1px;
}

.btn-finalizar {
    background-color: var(--botoesFiltrarFinalizar-bg-color);
    color: #ffffff;
}

.btn-finalizar:hover {
    background-color: var(--botoesFiltrarFinalizarHover-bg-color);
}

.btn-limparCarrinho {
    background-color: rgb(255, 255, 255);
    color: var(--botoesFiltrarFinalizar-bg-color);
}

.salvar-carrinho {
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    margin-top: 10px;
    top: 10px;
}

.salvar-carrinho:hover {
    color: red;
}

/*#endregion */


@media(max-width: 923px) {
    .btn-shop-responsivo {
        display: block;
        top: 80px;
        left: 16rem;
    }
    .btn-shop{
        display: none;
    }
    .cart {
        display: none;
    }

    .inputDesktop {
        display: none;
    }

}

@media (min-width: 376px) and (max-width: 436px) {
    .btn-shop-responsivo {
        display: block;
        top: 80px;
        left: 18rem;
    }

}

.subtotal{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 0 10px;
    margin-right: 10px; */
    border-top: 2px solid rgb(233, 233, 233);
    font-size: 14px;
    /* font-size: 18px; */
}

  th {
    /* margin-left: 10px; */
    text-align: left;
    padding: 2px 20px;
  }

  td{
    margin-left: 10px;
    text-align: left;
    padding: 2px 20px;

  }

  .th-qtd{
    text-align: right;
  }
 

