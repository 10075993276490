* {
    box-sizing: inherit;
    padding: 0px;
    margin: 0px;
    list-style: none;
    text-decoration: none;
    font-family: "Open Sans", sans-serif;
}

a {
    text-decoration: none;
    color: black;
}

.cards {
    display: flex;
    margin: 0 auto;
    width: 100%;
    flex-wrap: wrap;
    /* RESPONSIVE AJUSTE */
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 923px) {
        display: grid;
        grid-template-columns: 100%;
        justify-items: center;
    }

    /* @media (max-width: 1366px) {
        padding: 2rem;
    } */
}

.card {
    width: 280px;
    max-width: 280px;
    min-width: 280px;
    height: 430px;
    min-height: 430px;
    max-height: 430px;
    padding: 10px;
    margin: 10px;
    border: solid 1px rgba(133, 130, 130, 0.5);
    border-radius: 5px;
}

.card-imagem {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
}

.card-capa {
    height: 250px;
    justify-content: center;
}

.card-capa img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}


.card-tags {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;

}

.cardDesconto {
    background: var(--cardDesconto-bg-color);
    margin-left: 12rem;
    padding: 0px 5px;
    z-index: 1;
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    z-index: 0;
}

.inativo {
    background: none !important;
}

.cardMaisVendidos {
    position: absolute;
    background: var(--cardMaisVendidos-bg-color);
    width: 80px;
    height: 30px;
    top: 0px;
    color: #fff;
    font-size: 13px;
    z-index: 0;
    bottom: 1rem;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    font-size: 13px;
    z-index: 0;
}

.cardLancamentos {
    position: absolute;
    background: var(--cardLancamentos-bg-color);
    width: 80px;
    max-width: 80px;
    height: 30px;
    top: 40px;
    color: #fff;
    font-size: 13px;
    z-index: 0;
    bottom: 2rem;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
}

.cardPreVenda {
    position: absolute;
    background: var(--cardPreVenda-bg-color);
    width: 80px;
    height: 30px;
    top: 80px;
    color: #fff;
    font-size: 13px;
    z-index: 0;
    bottom: 2rem;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    font-size: 13px;
    z-index: 0;
}

.card-infos {
    font-size: 14px;
}

.card-titulo {
    text-align: center;
    font-weight: 400;
    min-height: 50px;
}

.card-valor {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 18px;
}

.card-estoque {
    font-size: 16px;
}

.card-botoes {
    display: flex;
    padding-top: 10px;
}

.card-quantidade {
    width: 30%;
    display: inline-block;
    padding-right: 10px;
}

.card-submit {
    width: 100%;
    display: inline-block;
}

.contadorLivros {
    width: 100%;
    font-size: 14px;
    border: none;
    padding: 1px;
    text-align: center;
    background: rgb(238, 238, 238);
    line-height: 50px;
    font-weight: bold;
}

.contadorLivros::placeholder {
    font-weight: normal;
}

.adcCarrinho {
    background-color: var(--botaoAdicionarCart-bg-color);
    color: rgb(255, 255, 255);
    cursor: pointer;
    border: none;
    width: 100%;
    height: 100%;
}

.adcCarrinhoDisable {
    background-color: darkgray;
    color: rgb(255, 255, 255);
    cursor: not-allowed;
    border: none;
    width: 100%;
    height: 100%;
}

.dePorValoresCard{
    min-height: 45px;
    height: 45px;
    max-height: 45px;

}
.valorCard{
    font-size: 12px;
}
.valorFinalCard{
    font-weight: bold;
    font-size: 16px;
}

.valorFinalIndividualCard{
    font-weight: bold;
    font-size: 16px;
    padding-top: 18px;
}