
:root {
    --main-bg-color: #fff;  /* ALTERA A COR DE FUNDO DO HEADER */
    --menu-bg-color: #313131; /* ALTERA A COR DO MENU */
    --menuHover-bg-color: #423e3e; /* ALTERA A COR DO HOVER MENU */
    --botaoAdicionarCart-bg-color: #38ad11; /* ALTERA A COR DO BOTÃO ADICIONAR AO CARRINHO */
    --botaoAdicionarDisable-bg-color: #A9A9A9; /* ALTERA A COR DO BOTÃO ADICIONAR QUANDO DESABILITADO */
    --botoesFiltrarFinalizar-bg-color: #313131; /* ALTERA A COR DOS BOTOES DE FILTRAR E FINALIZAR*/
    --botoesFiltrarFinalizarHover-bg-color: #423e3e; /* ALTERA A COR DO HOVER DOS BOTOES DE FILTRAR E FINALIZAR*/
    --footer-bg-color: #111; /* ALTERA A COR DO FOOTER */
    --barraPesquisa-bg-color: rgba(243,243,243,1.0); /* ALTERA A COR DE FUNDO DA BARRA DE PESQUISA */
    --filtrarHover-bg-color: #423e3e; /* HOVER DO BOTÃO FILTRO */
    --iconeMenuHamburger-bg-color: #000;  /* ALTERA A COR DO ICONE DO MENU RESPONSIVO */
    --tabelas-bg-color: #313131; /* ALTERA A COR DAS TABELAS */
    --botoesPaginacao-bg-color: #111; /* ALTERA A COR DOS BOTOES DE PAGINACAO*/

}