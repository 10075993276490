.links {
  text-align: center;
}

.paginator {
  padding: 5px 0px 5px 0px;
  /* margin-left: -1px; */
  line-height: 1.42857143;
  color: #606060;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #555555;
  text-decoration: none;
  max-width: 35px;
  width: 35px;
  text-align: center;
  vertical-align: center;
  min-height: 15px;
  max-height: 15px;
  height: 15px;
}

.paginatorPage {
  padding: 2px 10px 1px 10px;
  /* margin-left: -1px; */
  line-height: 1.42857143;
  color: #606060;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #555555;
  text-decoration: none;
  max-width: 50px;
  width: 50px;
  text-align: center;
  vertical-align: center;
  min-height: 15px;
  max-height: 15px;
  height: 15px;
}

.paginator:hover {
  background: #555555;
  text-decoration: none;
}

.paginatorActive {
  padding: 2px 10px 1px 10px;
  /* margin-left: -1px; */
  line-height: 1.42857143;
  color: #fff;
  text-decoration: none;
  background-color: #555555;
  border: 1px solid #555555;
  text-decoration: none;
  max-width: 35px;
  width: 35px;
  text-align: center;
  vertical-align: center;
  min-height: 15px;
  max-height: 15px;
  height: 15px;
  font-size: 10px;

}

.paginatorPageActive {
  padding: 2px 10px 1px 10px;
  /* margin-left: -1px; */
  line-height: 1.42857143;
  color: #fff;
  text-decoration: none;
  background-color: #555555;
  border: 1px solid #555555;
  text-decoration: none;
  max-width: 35px;
  width: 35px;
  text-align: center;
  vertical-align: center;
  min-height: 15px;
  max-height: 15px;
  height: 15px;

}

.paginatorActive:hover {
  background: #555555;
  text-decoration: none;
}