.table {
    width: 100%;
    margin-bottom: 1rem;
  
}

.headerTable {
    background: #ebeaea;
    border-bottom: 1px solid #606060;
  }

  .headerTable th {
    font-size: 16px;
    font-weight: bold;
    color: #606060;
    text-align: center;
  }
  /* .headerTable th:first-child {
    border-left: none;
  } */

 
 .bodyTable td,  .bodyTable th {
    border: 1px solid #AAAAAA;
    padding: 5px 2px;
  }
   .bodyTable td {
    font-size: 13px;
  }
   .bodyTable tr:hover {
    background-color: #d1d1d1;
  }
  
  .bodyTable tr:nth-child(even) {
    background: #f6f6f6;
  } 
  .bodyTable tr:nth-child(odd) {
    background: #fff ;
  }
  
   .headerTable {
    background: #ebeaea;
    border-bottom: 1px solid #555555;
  }
  

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.table {
  background-color: #EEEEEE;
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    text-align: center;
    border-collapse: collapse;
    }

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

body {
    margin: 0;
    padding: 0 0 0 0;
    font-family: sans-serif;
}

.table .headerTable th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.clickable {
    cursor: pointer;
}

.clickable:hover{
    color: #111;
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent;
}

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: bold;
    text-align: -internal-center;
}



.td {
    display: table-cell;
    vertical-align: inherit;
}