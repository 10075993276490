.RotasEcarrinho{
    display: flex;
    width: 100%;
}

.pin{
    display: block;
    width: 100%;
    max-width: 445px;
}

.pinOff{
    display: none;
}

.rotas{
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: 830px;
}

@media(max-width: 923px){
    .carrinhoOff{    
        display: none;
    }
}